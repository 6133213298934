import * as React from 'react';
import {
  ActionCreator,
  ActionCreatorsMapObject,
  AnyAction,
  bindActionCreators,
} from 'redux';
import { useAppDispatch } from 'src/store.types';

type UseActions = {
  <A, M extends ActionCreatorsMapObject<A>>(
    actions: M,
    deps?: React.DependencyList
  ): M;

  <A, MA extends readonly ActionCreatorsMapObject<A>[]>(
    actions: MA,
    deps?: React.DependencyList
  ): MA;

  <A, C extends ActionCreator<A>>(actions: C, deps?: React.DependencyList): C;
};

const useActions: UseActions = (
  actions:
    | ActionCreatorsMapObject<AnyAction>
    | ActionCreatorsMapObject<AnyAction>[]
    | ActionCreator<AnyAction>,
  deps: React.DependencyList = []
) => {
  const dispatch = useAppDispatch();
  return React.useMemo(() => {
    if (Array.isArray(actions)) {
      return actions.map(a => bindActionCreators(a, dispatch));
    }
    if (typeof actions === 'object') {
      const ret = { ...actions };
      for (const actionName in ret) {
        ret[actionName] = bindActionCreators(ret[actionName], dispatch);
      }
      return ret;
    }
    if (typeof actions === 'function') {
      return bindActionCreators(actions, dispatch);
    }

    // Fall through, should never occur. -md
    return bindActionCreators(actions, dispatch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actions, dispatch, ...deps]);
};

export default useActions;
